import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CountUp } from "countup.js";

const About = () => {
  useEffect(() => {
    let clients = new CountUp(document.getElementById("clients"), 1200, { enableScrollSpy: true });
    let quality = new CountUp(document.getElementById("quality"), 100, { enableScrollSpy: true });
    let experience = new CountUp(document.getElementById("experience"), 30, { enableScrollSpy: true });
    let satisfaction = new CountUp(document.getElementById("satisfaction"), 100, { enableScrollSpy: true });
  });

  const testimonialsCarousel = {
    autoplay: true,
    dots: false,
    autoplayTimeout: 8000,
    autoplaySpeed: 600,
    loop: true,
    items: 1,
  };

  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="aboutHero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>About Us</h1>
                <h2>
                  What do we do?
                  <br />
                  Over the years a lot has changed, but our goals remain the same.
                </h2>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              <img src={require("../static/img/aboutUs.png")} className="img-fluid animated py-4" alt="aboutUs" />
            </div>
          </div>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right"></div>

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                <div className="section-title pb-4" data-aos="fade-in">
                  <h2>About Us</h2>
                  <p>What do we do?</p>
                </div>

                <p>
                  Founded in 1991 by Mr. Madan Sharma, Indo Aircon Engineers has come a long way serving people by more than <b>30+ years</b> of experience with experienced well-trained professionals. Our company has successfully covered many projects and we ensure the client satisfaction. We offer services in water and air-cooled chiller, central air-conditioning plants, ductable air-conditioners, all types of compressor repair, all type of window and split air-conditioners maintenance and amc's, panel ac, air dryer, and all sorts of commercial air-conditioning maintenance. We hope you will enjoy our services as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
                </p>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div className="icon">
                    <i className="icofont-ui-calendar"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Annual Maintenance Contract
                    </a>
                  </h4>
                  <p className="description">
                    We at Indo Aircon Engineers believe in providing our valued customers the best service and quality performance. Striving to keep our commitments and to ensure best product performance, we offer to you exciting <b>Annual Maintenance Contract</b> to cover the service support for all your products.
                  </p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon">
                    <i className="icofont-snow"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Completing All Air Conditioning Needs
                    </a>
                  </h4>
                  <p className="description">Indo Aircon Engineers is one such company that has a vision to be leader of their fields and we feel we have that strength and power to satisfy all our clients demands. You can count on us for all your home and commercial Air-Conditioning needs.</p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon">
                    <i className="icofont-repair"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      24X7 Customer Support
                    </a>
                  </h4>
                  <p className="description">For all kind of support and maintenence service, We are always ready to assist you, 24 hours a day, 7 days a week. It’s good to know Indo Aircon Engineers are always just a phone call away!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Testimonials Section ======= --> */}
        <section id="testimonials" className="testimonials">
          <div className="container">
            <OwlCarousel className="testimonials-carousel" {...testimonialsCarousel} data-aos="zoom-in">
              <div className="testimonial-item">
                <img src={require("../static/img/testimonials/testimonials-1.png")} className="testimonial-img" alt="testimonials-1" />
                <h3>Vipin Kumar</h3>
                <p>I am very happy with AC Servicing which was done by your professionals. I will definitely take your services again in the future and also recommend them to others. I hope you guys always maintain the same level of quality and professionalism as do now.</p>
              </div>

              <div className="testimonial-item">
                <img src={require("../static/img/testimonials/testimonials-2.png")} className="testimonial-img" alt="testimonials-2" />
                <h3>Vikas Rana</h3>
                <p>Good Professional services, give the full and persistent solution, highly recommended. The team members are trained and soft-spoken.</p>
              </div>

              <div className="testimonial-item">
                <img src={require("../static/img/testimonials/testimonials-3.png")} className="testimonial-img" alt="testimonials-3" />
                <h3>Sankalp Kashyap</h3>
                <p>Indo Aircon Engineers came today to fix my Bluestar Ductable AC. They figured out the issue quickly & fixed the problem quickly as well, Informing us of everything throughout the whole process. Definitely would have them back with open arms if we have another issue. Grateful for their kindness, professional courtesy, and abilities to be able to help.</p>
              </div>
            </OwlCarousel>
          </div>
        </section>
        {/* <!-- End Testimonials Section --> */}

        {/* <!-- ======= Counts Section ======= --> */}
        <section id="counts" className="counts">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="count-box" data-aos="fade-up" data-aos-delay="100">
                  <i className="icofont-simple-smile"></i>
                  <span id="clients">1200</span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box" data-aos="fade-up" data-aos-delay="200">
                  <i className="icofont-repair"></i>
                  <span className="prsnt" id="quality">
                    100
                  </span>
                  {/* <div class="show js-count">160</div> */}
                  <p>Service Quality</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div className="count-box" data-aos="fade-up" data-aos-delay="300">
                  <i className="icofont-handshake-deal"></i>
                  <span className="plus" id="experience">
                    30
                  </span>
                  <p>Years of Experience</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box" data-aos="fade-up" data-aos-delay="400">
                  <i className="icofont-business-man"></i>
                  <span className="prsnt" id="satisfaction">
                    100
                  </span>
                  <p>Client Satisfaction</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Counts Section --> */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default About;
