import React from "react";

const Services = () => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>Services</h1>
                <h2>
                  Complete solution for all Commercial and Domestic
                  <br />
                  Air Conditioning services
                </h2>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              <img src={require("../static/img/services.png")} className="img-fluid animated" alt="Services" />
            </div>
          </div>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= Services Section ======= --> */}
        <section id="Services" className="Services">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title" data-aos="fade-up">
                  <h2>Best Services</h2>
                  <p>Services we Provide</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-lg-4">
                <div className="InMainSer" data-aos="zoom-in" data-aos-delay="50">
                  <div className="icon" style={{ background: "#f8bbd0" }}>
                    <i className="icofont-tools-bag" style={{ color: "#d81b60" }}></i>
                  </div>
                  <h2>Maintenance</h2>
                </div>
              </div>

              <div className="col-md-4 col-lg-4" data-aos="zoom-in" data-aos-delay="100">
                <div className="InMainSer">
                  <div className="icon" style={{ background: "#b2dfdb" }}>
                    <i className="icofont-tools" style={{ color: "#00897b" }}></i>
                  </div>
                  <h2>Installation</h2>
                </div>
              </div>

              <div className="col-md-4 col-lg-4" data-aos="zoom-in" data-aos-delay="150">
                <div className="InMainSer">
                  <div className="icon" style={{ background: "#e1bee7" }}>
                    <i className="icofont-tools-alt-2" style={{ color: "#8e24aa" }}></i>
                  </div>
                  <h2>Service</h2>
                </div>
              </div>
            </div>

            <div className="row service-left" id="Central_AC_Plant">
              <h2>Central Air-Conditioning Plants</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Central_AC_Plant/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Central_AC_Plant/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Central_AC_Plant/img-5.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-right" id="AC_Chillers">
              <h2>Air-Cooled Chillers</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/AC_Chillers/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/AC_Chillers/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/AC_Chillers/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-left" id="Water_Chillers">
              <h2>Water-Cooled Chillers</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Water_Chillers/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Water_Chillers/img-4.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Water_Chillers/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-right" id="Ductable_AC">
              <h2>Ductable Air-Conditioners</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Ductable_AC/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Ductable_AC/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Ductable_AC/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-left" id="Cassette_AC">
              <h2>Cassette Air-Conditioners</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cassette_AC/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cassette_AC/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cassette_AC/img-5.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-right" id="Cold_Room">
              <h2>Cold Room & Cold Storage</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cold_Room/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cold_Room/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Cold_Room/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-left" id="Compressor_Repairs">
              <h2>Compressor Repairs</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Compressor_Repairs/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Compressor_Repairs/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Compressor_Repairs/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-right" id="Refrigerated_Driers">
              <h2>Refrigerated Air Driers</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Refrigerated_Driers/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Refrigerated_Driers/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Refrigerated_Driers/img-3.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-left" id="Panel_AC">
              <h2>Panel Air-Conditioners</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Panel_AC/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Panel_AC/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Panel_AC/img-4.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>

            <div className="row service-right" id="Window_Split_AC">
              <h2>Window & Split Air-Conditioners</h2>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Window_Split_AC/img-1.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Window_Split_AC/img-2.jpg")} className="img-fluid animated" alt="Services" />
              </div>
              <div className="col-md-4">
                <img src={require("../static/img/gallery/Window_Split_AC/img-4.jpg")} className="img-fluid animated" alt="Services" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default Services;
