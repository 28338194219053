import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../routes";

const Sitemap = () => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>Site Map</h1>
                <h2>
                  Find all links
                  <br />
                  at one place
                </h2>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              <img src={require("../static/img/sitemap.png")} className="img-fluid animated" alt="Gallery" style={{ width: "70%" }} />
            </div>
          </div>
        </div>
        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x={50} y={3} fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x={50} y={0} fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x={50} y={9} fill="#fff" />
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= Gallery Section ======= --> */}
        <section id="details" className="details">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <div className="section-title pb-4" data-aos="fade-in">
                  <h2>Check Out</h2>
                  <p>Our Site Map</p>
                </div>
              </div>
            </div>

            <div className="row content">
              <div className="col-12">
                <ul>
                  <li data-aos="fade-left" data-aos-delay="100" className="aos-init aos-animate">
                    <Link to={WebRoutes.HOME}>Home</Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="150" className="aos-init aos-animate">
                    <Link to={WebRoutes.ABOUT}>About Us</Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200" className="aos-init aos-animate">
                    <Link to={WebRoutes.SERVICES}>Services</Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="250" className="aos-init aos-animate">
                    <Link to={WebRoutes.GALLERY}>Gallery</Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="300" className="aos-init aos-animate">
                    <Link to={WebRoutes.CONTACT}>Contact Us</Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="350" className="aos-init aos-animate">
                    <Link to={WebRoutes.SITEMAP}>Site Map</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Gallery Section --> */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default Sitemap;
