import React, { useEffect } from "react";
import Isotope from "isotope-layout";

const Gallery = () => {
  useEffect(() => {
    setTimeout(() => {
      galleryInit();
    }, 500);
  });

  const galleryInit = () => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    let galleryContainer = document.querySelector(".gallery-container");
    if (galleryContainer) {
      let galleryIsotope = new Isotope(galleryContainer, {
        itemSelector: ".gallery-item",
        layoutMode: "fitRows",
      });

      let galleryFilters = select("#gallery-flters li", true);

      on(
        "click",
        "#gallery-flters li",
        function (e) {
          e.preventDefault();
          galleryFilters.forEach((el) => {
            el.classList.remove("filter-active");
          });
          this.classList.add("filter-active");

          galleryIsotope.arrange({
            filter: this.getAttribute("data-filter"),
          });
        },
        true
      );
    }
  };

  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>Gallery</h1>
                <h2>
                  Some of our work done in past
                  <br />
                  Check our Gallery
                </h2>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              <img src={require("../static/img/gallery.png")} className="img-fluid animated" alt="gallery" style={{ width: "70%" }} />
            </div>
          </div>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      {/* {setTimeout(
        () => (
          <> */}
      <main id="main">
        {/* <!-- ======= Gallery Section ======= --> */}
        <section id="gallery" className="gallery">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <div className="section-title pb-4" data-aos="fade-in">
                  <h2>Check Out</h2>
                  <p>Our Gallery</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="gallery-flters">
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  <li data-filter=".Central_AC_Plant">Central Air-Conditioning Plants</li>
                  <li data-filter=".AC_Chillers">Air-Cooled Chillers</li>
                  <li data-filter=".Water_Chillers">Water-Cooled Chillers</li>
                  <li data-filter=".Ductable_AC">Ductable Air-Conditioners</li>
                  <li data-filter=".Cassette_AC">Cassette Air-Conditioners</li>
                  <li data-filter=".Cold_Room">Cold Room & Cold Storage</li>
                  <li data-filter=".Compressor_Repairs">Compressor Repairs</li>
                  <li data-filter=".Refrigerated_Driers">Refrigerated Air Driers</li>
                  <li data-filter=".Panel_AC">Panel Air-Conditioners</li>
                  <li data-filter=".Window_Split_AC">Window & Split Air-Conditioners</li>
                </ul>
              </div>
            </div>

            <div className="row gallery-container g-4">
              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item AC_Chillers">
                <a href={require("../static/img/gallery/AC_Chillers/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/AC_Chillers/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Ductable_AC">
                <a href={require("../static/img/gallery/Ductable_AC/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Ductable_AC/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-6.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-6.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-7.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-7.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Ductable_AC">
                <a href={require("../static/img/gallery/Ductable_AC/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Ductable_AC/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-6.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-6.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Water_Chillers">
                <a href={require("../static/img/gallery/Water_Chillers/img-7.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Water_Chillers/img-7.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Central_AC_Plant">
                <a href={require("../static/img/gallery/Central_AC_Plant/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Central_AC_Plant/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-6.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-6.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Ductable_AC">
                <a href={require("../static/img/gallery/Ductable_AC/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Ductable_AC/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Ductable_AC">
                <a href={require("../static/img/gallery/Ductable_AC/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Ductable_AC/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Ductable_AC">
                <a href={require("../static/img/gallery/Ductable_AC/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Ductable_AC/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cold_Room">
                <a href={require("../static/img/gallery/Cold_Room/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cold_Room/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cold_Room">
                <a href={require("../static/img/gallery/Cold_Room/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cold_Room/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cold_Room">
                <a href={require("../static/img/gallery/Cold_Room/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cold_Room/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cold_Room">
                <a href={require("../static/img/gallery/Cold_Room/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cold_Room/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cold_Room">
                <a href={require("../static/img/gallery/Cold_Room/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cold_Room/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Compressor_Repairs">
                <a href={require("../static/img/gallery/Compressor_Repairs/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Compressor_Repairs/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Compressor_Repairs">
                <a href={require("../static/img/gallery/Compressor_Repairs/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Compressor_Repairs/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Compressor_Repairs">
                <a href={require("../static/img/gallery/Compressor_Repairs/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Compressor_Repairs/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item AC_Chillers">
                <a href={require("../static/img/gallery/AC_Chillers/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/AC_Chillers/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item AC_Chillers">
                <a href={require("../static/img/gallery/AC_Chillers/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/AC_Chillers/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item AC_Chillers">
                <a href={require("../static/img/gallery/AC_Chillers/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/AC_Chillers/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Cassette_AC">
                <a href={require("../static/img/gallery/Cassette_AC/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Cassette_AC/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Panel_AC">
                <a href={require("../static/img/gallery/Panel_AC/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Panel_AC/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Window_Split_AC">
                <a href={require("../static/img/gallery/Window_Split_AC/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Window_Split_AC/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Window_Split_AC">
                <a href={require("../static/img/gallery/Window_Split_AC/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Window_Split_AC/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Refrigerated_Driers">
                <a href={require("../static/img/gallery/Refrigerated_Driers/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Refrigerated_Driers/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Compressor_Repairs">
                <a href={require("../static/img/gallery/Compressor_Repairs/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Compressor_Repairs/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Refrigerated_Driers">
                <a href={require("../static/img/gallery/Refrigerated_Driers/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Refrigerated_Driers/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Panel_AC">
                <a href={require("../static/img/gallery/Panel_AC/img-2.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Panel_AC/img-2.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Panel_AC">
                <a href={require("../static/img/gallery/Panel_AC/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Panel_AC/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Refrigerated_Driers">
                <a href={require("../static/img/gallery/Refrigerated_Driers/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Refrigerated_Driers/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Refrigerated_Driers">
                <a href={require("../static/img/gallery/Refrigerated_Driers/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Refrigerated_Driers/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Panel_AC">
                <a href={require("../static/img/gallery/Panel_AC/img-1.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Panel_AC/img-1.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Window_Split_AC">
                <a href={require("../static/img/gallery/Window_Split_AC/img-3.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Window_Split_AC/img-3.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Window_Split_AC">
                <a href={require("../static/img/gallery/Window_Split_AC/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Window_Split_AC/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Compressor_Repairs">
                <a href={require("../static/img/gallery/Compressor_Repairs/img-4.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Compressor_Repairs/img-4.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>

              <div className="col-lg-3 col-md-6 gallery-item Window_Split_AC">
                <a href={require("../static/img/gallery/Window_Split_AC/img-5.jpg")} data-fancybox="gallery" className="venobox preview-link">
                  <img src={require("../static/img/gallery/Window_Split_AC/img-5.jpg")} className="img-fluid" loading="lazy" alt="gallery" />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Gallery Section --> */}
      </main>
      {/* </>
        ),
        500
      )} */}
      {/* <!-- End #main --> */}
    </>
  );
};

export default Gallery;
