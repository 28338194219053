import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { WebRoutes } from "./../../routes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Footer = () => {
  const location = useLocation();
  const footerClass = location.pathname === "/contact" || location.pathname === "/about" ? "mt-0" : "";

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 800) {
      setVisible(false);
    }
  };

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" className={footerClass}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250" className="footerWave">
          <path fill="#02046b" fillOpacity="1" d="M0,224L80,213.3C160,203,250,181,480,181.3C640,181,800,203,960,197.3C1120,192,1280,160,1360,144L1440,128L1440,250L1360,250C1280,250,1120,250,960,250C800,250,640,250,480,250C250,250,160,250,80,250L0,250Z"></path>
        </svg>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">
                  <img src={require("../../static/img/logo.png")} className="img-fluid" />
                  <p className="pb-3">Indo Aircon Engineers rates as the reputed contractor providing complete solution for all sort of Commercial and Domestic, Air Conditioning services for Hotels, Hospitals, Restaurants, Office Spaces, Institutes, Food & Beverage Industries & Industrial projects.</p>
                  <p className="pb-3 text-center">
                    <b>GST No. </b>09GQZPS2613Q1ZJ
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://wa.me/918447708802?text=Hi,%20I%20have%20a%20query" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-brand-whatsapp"></i>
                    </a>
                    <a href="https://www.facebook.com/Indo-Aircon-Engineers-100463789240631" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/indoairconengineers" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-instagram"></i>
                    </a>
                    <a href="https://in.linkedin.com/in/ayush-sharma-629223195" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/indoairconeng" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <Link to={WebRoutes.HOME}>Home</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.ABOUT}>About Us</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.SERVICES}>Services</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.CONTACT}>Contact Us</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Explore More</h4>
                <ul>
                  <li>
                    <Link to={WebRoutes.GALLERY}>Gallery</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.SITEMAP}>Site Map</Link>
                  </li>
                  <li>
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Terms of service
                    </a>
                  </li>
                  <li>
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Privacy policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-links ContactUs">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <a href="tel:+91 98101 25522">+91 98101 25522,&nbsp;</a>
                    <a href="tel:+91 84477 08802" className="p-0">
                      +91 84477 08802
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@indoairconengineers.com">info@indoairconengineers.com</a>
                  </li>
                  <li>
                    <a href="mailto:indo_aircon442@yahoo.com">indo_aircon442@yahoo.com</a>
                  </li>
                  <li>
                    <a href="https://goo.gl/maps/EoPg3kWXc72nMq5S9" target="_blank" rel="noopener noreferrer">
                      <b className="d-block">Corporate Address:</b>Shop No. 1, B-88, DLF Dilshad Extention-2, Ghaziabad, Uttar Pradesh-201005
                    </a>
                  </li>
                  <li>
                    <a href="https://goo.gl/maps/EoPg3kWXc72nMq5S9" target="_blank" rel="noopener noreferrer">
                      <b className="d-block">Registered Address:</b>B-77/F-4, DLF Dilshad Extention-2, Ghaziabad, Uttar Pradesh-201005
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg">
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 text-left">
                  Copyright &copy; {new Date().getFullYear()}
                  <strong>
                    <span> Indo Aircon Engineers</span>
                  </strong>
                  . All Rights Reserved.
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-right mt-3 mt-md-0">
                  <OverlayTrigger placement="top" overlay={<Tooltip>by @vipinwap07</Tooltip>}>
                    <a href="https://github.com/vipinwap07" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>
                      Made with <i className="icofont-heart-alt" style={{ color: "#eb3349", padding: "0px 2px" }}></i> in India
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}

      {/* <OverlayTrigger
        placement={"top"}
        overlay={ */}
      {/* <Tooltip id={`tooltip-top`}>
        Tooltip on <strong>top</strong>.
      </Tooltip> */}
      {/* }
      >
        <Button variant="secondary">Tooltip on top</Button>
      </OverlayTrigger> */}
      {/* <Tooltip></Tooltip> */}

      <span onClick={() => backToTop()} className={`back-to-top ${visible ? "animate__animated animate__fadeInUp" : "animate__animated animate__zoomOutDown"}`}>
        <i className="icofont-simple-up"></i>
      </span>
      {/* <div id="preloader"></div> */}
    </>
  );
};

export default Footer;
