import { Route, Routes } from "react-router-dom";
import { WebRoutes } from "./routes";
import BaseComponent from "./components";
import ScrollToTop from "./components/common/ScrollToTop";
import Home from "./components/home";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Contact from "./components/contact";
import Sitemap from "./components/sitemap";

function App() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<BaseComponent />}>
          <Route path={WebRoutes.Home} index element={<Home />} />
          <Route path={WebRoutes.ABOUT} element={<About />} />
          <Route path={WebRoutes.SERVICES} element={<Services />} />
          <Route path={WebRoutes.GALLERY} element={<Gallery />} />
          <Route path={WebRoutes.CONTACT} element={<Contact />} />
          <Route path={WebRoutes.SITEMAP} element={<Sitemap />} />
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
