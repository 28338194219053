import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { WebRoutes } from "../../routes";
// import { WebRoutes } from "../../routes";

const Header = () => {
  //  ========= * Mobile nav toggle =========
  // const handleMobileNavToggle = (e) => {
  //   document.querySelector("#navbar").classList.toggle("navbar-mobile");
  //   document.querySelector(".mobile-nav-toggle").classList.toggle("bi-list");
  //   document.querySelector(".mobile-nav-toggle").classList.toggle("bi-x");
  // };

  useEffect(() => {
    //  ========= * Easy selector helper function =========
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    //  ========= * Easy event listener function =========
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    //  ========= Scrool with ofset on links with a class name .scrollto =========
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );
    //  ========= * Scrolls to an element with header offset =========
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("fixed-top")) {
        offset += 100;
      }

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };
  });

  return (
    <>
      {/* <button type="button" className="mobile-nav-toggle d-lg-none">
        <i className="icofont-navigation-menu"></i>
      </button> */}
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <div className="logo mr-auto">
            <Link to={WebRoutes.HOME}>
              <img src={require("../../static/img/logo.png")} alt="Logo" className="img-fluid" />
            </Link>
          </div>

          <nav className="nav-menu d-none d-lg-block" id="navbar">
            <ul id="ulNav">
              <li className="active">
                <NavLink to={WebRoutes.HOME}>Home</NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.ABOUT}>About Us</NavLink>
              </li>
              <li className="drop-down">
                <NavLink to={WebRoutes.SERVICES}>Services</NavLink>
                <ul>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Central_AC_Plant`}>
                      Central Air-Conditioning Plants
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#AC_Chillers`}>
                      Air-Cooled Chillers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Water_Chillers`}>
                      Water-Cooled Chillers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Ductable_AC`}>
                      Ductable Air-Conditioners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Cassette_AC`}>
                      Cassette Air-Conditioners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Cold_Room`}>
                      Cold Room & Cold Storage
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Compressor_Repairs`}>
                      Compressor Repairs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Refrigerated_Driers`}>
                      Refrigerated Air Driers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Panel_AC`}>
                      Panel Air-Conditioners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="scrollto" to={`${WebRoutes.SERVICES}#Window_Split_AC`}>
                      Window & Split Air-Conditioners
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={WebRoutes.GALLERY}>Gallery</NavLink>
              </li>
              <li>
                <NavLink to={WebRoutes.CONTACT}>Contact Us</NavLink>
              </li>
            </ul>
          </nav>
          {/* <!-- .nav-menu --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}
    </>
  );
};

export default Header;
