import React from "react";

const Home = () => {
  const clientArr = [];
  for (let i = 1; i <= 17; i++) {
    clientArr.push(i);
  }

  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="homeHero">
        <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">
          <div className="carousel-inner">
            {/* <!-- Slide 1 --> */}
            <div className="carousel-item active" style={{ backgroundImage: require("../static/img/banner/banner-1.jpg").default }}>
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">
                  <small>Welcome to</small>
                  <br />
                  Indo Aircon Engineers
                  <br />
                </h2>
                <p className="animate__animated fanimate__adeInUp">We welcome the opportunity to work with you and we want to have long-term relationships with customers in which trust and understanding are mutual. Assuring you our best services at all times.</p>
                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                  About Us
                </a>
              </div>
            </div>

            {/* <!-- Slide 2 --> */}
            <div className="carousel-item">
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">
                  <small>Quality Service</small>
                  <br />
                  Best Services, Client Satisfaction
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  You can contact our customer care for any queries.
                  <br />
                  We are eco-friendly , clean, quality work. For all our customers we have additional benefits.
                </p>
                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                  About Us
                </a>
              </div>
            </div>

            {/* <!-- Slide 3 --> */}
            <div className="carousel-item">
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">
                  <small>Expert Service</small>
                  <br />
                  We are Professionals
                </h2>
                <p className="animate__animated animate__fadeInUp">We have well-trained employees and latest state of art machinery. We cherish to serve our Customers in the best possible manner. You can connect with us for any kinds of issues related to your air conditioner.</p>
                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">
                  About Us
                </a>
              </div>
            </div>
          </div>

          <ol className="carousel-indicators">
            <li data-target="#heroCarousel" data-slide-to="0" className="active"></li>
            <li data-target="#heroCarousel" data-slide-to="1"></li>
            <li data-target="#heroCarousel" data-slide-to="2"></li>
          </ol>

          <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon icofont-dotted-left" aria-hidden="true"></span>
          </a>

          <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon icofont-dotted-right" aria-hidden="true"></span>
          </a>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right"></div>

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
                <div className="section-title pb-4" data-aos="fade-in">
                  <h2>About Us</h2>
                  <p>What do we do?</p>
                </div>

                <p>
                  Founded in 1991 by Mr. Madan Sharma, Indo Aircon Engineers has come a long way serving people by more than <b>30+ years</b> of experience with experienced well-trained professionals. Our company has successfully covered many projects and we ensure the client satisfaction. We offer services in water and air-cooled chiller, central air-conditioning plants, ductable air-conditioners, all types of compressor repair, all type of window and split air-conditioners maintenance and amc's, panel ac, air dryer, and all sorts of commercial air-conditioning maintenance. We hope you will enjoy our services as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
                </p>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div className="icon">
                    <i className="icofont-ui-calendar"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Annual Maintenance Contract
                    </a>
                  </h4>
                  <p className="description">
                    We at Indo Aircon Engineers believe in providing our valued customers the best service and quality performance. Striving to keep our commitments and to ensure best product performance, we offer to you exciting <b>Annual Maintenance Contract</b> to cover the service support for all your products.
                  </p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon">
                    <i className="icofont-snow"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      Completing All Air Conditioning Needs
                    </a>
                  </h4>
                  <p className="description">Indo Aircon Engineers is one such company that has a vision to be leader of their fields and we feel we have that strength and power to satisfy all our clients demands. You can count on us for all your home and commercial Air-Conditioning needs.</p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon">
                    <i className="icofont-repair"></i>
                  </div>
                  <h4 className="title">
                    <a href="https://indoairconengineers.com" onClick={(e) => e.preventDefault()}>
                      24X7 Customer Support
                    </a>
                  </h4>
                  <p className="description">For all kind of support and maintenence service, We are always ready to assist you, 24 hours a day, 7 days a week. It’s good to know Indo Aircon Engineers are always just a phone call away!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Details Section ======= --> */}
        <section id="details" className="details">
          <div className="container">
            <div className="row content">
              <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src={require("../static/img/workers.png")} className="img-fluid" loading="lazy" alt="workers" />
              </div>
              <div className="col-md-7 pt-3 order-2 order-md-1" data-aos="fade-up">
                <div className="section-title" data-aos="fade-up">
                  <h2>Best Services</h2>
                  <p>Why choose us?</p>
                </div>

                <p data-aos="fade-in">When you choose us, you choose to give value to your money, you choose to care for yourself, because we are committed to provide the services you need as effortlessly as possible.</p>
                <ul>
                  {/* <!-- <li data-aos="fade-left" data-aos-delay="50">ISO Certified Company</li> --> */}
                  <li data-aos="fade-left" data-aos-delay="100">
                    Experienced Team
                  </li>
                  <li data-aos="fade-left" data-aos-delay="150">
                    360 Degree Approach
                  </li>
                  <li data-aos="fade-left" data-aos-delay="200">
                    Intelligent Engineering
                  </li>
                  <li data-aos="fade-left" data-aos-delay="250">
                    Value Delivering Premises
                  </li>
                  <li data-aos="fade-left" data-aos-delay="300">
                    Focused and In-depth Knowledge
                  </li>
                  <li data-aos="fade-left" data-aos-delay="350">
                    Customized solution for each requirement
                  </li>
                  <li data-aos="fade-left" data-aos-delay="400">
                    Proven & established track record in HVAC field
                  </li>
                  <li data-aos="fade-left" data-aos-delay="450">
                    Strong and long lasting relationships with our Vendors and Clients.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Details Section --> */}

        {/* <!-- ======= Clients Section ======= --> */}
        <section id="client" className="client">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Clientele</h2>
              <p>Some of Our Valuable Clients</p>
            </div>

            <div className="row" data-aos="fade-left">
              {clientArr &&
                clientArr.length &&
                clientArr.map((i) => (
                  <div className="col-lg-3 col-md-4" key={i}>
                    <div className="icon-box" data-aos="zoom-in" data-aos-delay="50">
                      <img src={require(`../static/img/clients/client-${i}.png`)} alt={`client-${i}`} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
        {/* <!-- End Clients Section --> */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default Home;
