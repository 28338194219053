import React from "react";

const Contact = () => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>Get in Touch with Us</h1>
                <h2>
                  Whether you have a question, feedback or anything else, <br />
                  We are ready to answer all your calls
                </h2>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              <img src={require("../static/img/contactUs.png")} className="img-fluid animated" alt="contactUs" />
            </div>
          </div>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Leave a Message</p>
            </div>

            <div className="row">
              <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
                <div className="info">
                  <div className="address">
                    <i className="icofont-google-map"></i>
                    <h4>Location:</h4>
                    <a>
                      Shop No. 1, B-88, DLF Dilshad Extention-2, Ghaziabad
                      <br />
                      Uttar Pradesh-201005
                    </a>
                  </div>

                  <div className="email">
                    <i className="icofont-envelope"></i>
                    <h4>Email:</h4>
                    <a href="mailto:info@indoairconengineers.com">info@indoairconengineers.com</a>
                    <a href="mailto:indo_aircon442@yahoo.com">indo_aircon442@yahoo.com</a>
                  </div>

                  <div className="phone">
                    <i className="icofont-phone"></i>
                    <h4>Call:</h4>
                    <a href="tel:+91 98101 25522">+91 98101 25522</a>
                    <a href="tel:+91 84477 08802">+91 84477 08802</a>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
                <form method="POST" action="contact" role="form" className="email-form">
                  {/* {% csrf_token %} */}
                  <div className="form-row">
                    <input type="hidden" name="UserIP" defaultValue="" />
                    <div className="col-md-4 form-group">
                      <input type="text" className="form-control text-capitalize" maxLength="20" name="SenderName" placeholder="Full Name" required />
                    </div>
                    <div className="col-md-4 form-group">
                      <input type="email" className="form-control" maxLength="40" name="SenderEmail" placeholder="Email ID" required />
                    </div>
                    <div className="col-md-4 form-group">
                      <input type="text" className="form-control" maxLength="10" name="SenderMobile" placeholder="Mobile Number" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" maxLength="75" name="Subject" placeholder="Subject" required />
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" name="Message" maxLength="800" rows="7" placeholder="Message" required></textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>

                {/* <form method="POST" action="contact" role="form" className="email-form">
                  <div className="form-row">
                    <input type="hidden" name="UserIP" value="" />
                    <div className="col-md-4 form-group">
                      <input type="text" className="form-control text-capitalize" maxLength="20" name="SenderName" placeholder="Full Name" required value="Vipin Kumar" />
                    </div>
                    <div className="col-md-4 form-group">
                      <input type="email" className="form-control" maxLength="40" name="SenderEmail" placeholder="Email ID" required value="vipin@gmail.com" />
                    </div>
                    <div className="col-md-4 form-group">
                      <input type="text" className="form-control" maxLength="10" name="SenderMobile" placeholder="Mobile Number" required value="1234567890" />
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" maxLength="75" name="Subject" placeholder="Subject" required value="This is a Test Mail" />
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" name="Message" maxLength="800" rows="7" placeholder="Message" required>
                      Indo Aircon Engineers rates as the reputed contractor providing complete solution for all sort of Commercial and Domestic, Air Conditioning services for Hotels, Hospitals, Restaurants, Office Spaces, Institutes, Food & Beverage Industries & Industrial projects.
                    </textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13999.285176026568!2d77.3309169!3d28.6949919!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed0f88d8b22ef59c!2sIndo%20Aircon%20Engineers!5e0!3m2!1sen!2sin!4v1612536582789!5m2!1sen!2sin" frameBorder="0" title="map" allowFullScreen={true} aria-hidden="false" tabIndex="0"></iframe>
        </section>
      </main>
      {/* <!-- End #main --> */}

      <div className="modal fade" id="ThnxPop" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <a href="contact" className="icofont-close close"></a>
            <i className="icofont-check-circled"></i>
            <h2>Thank You!</h2>
            <p>
              Thank you for writing to us.
              <br />
              We'll get back in touch with you soon!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
