import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./common/header";
import Footer from "./common/footer";
import AOS from "aos";
import "aos/dist/aos.css";

const BaseComponent = () => {
  useEffect(() => {
    // document.querySelector('[data-toggle="tooltip"]').tooltip();
    window.addEventListener("load", () => {
      AOS.init({
        duration: 900,
        easing: "ease-in-out",
        once: true,
        mirror: false,
        disable: window.innerWidth < 768,
      });
    });
  });

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default BaseComponent;
